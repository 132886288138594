/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" {...props}>
    <path
      d="M1.938 7.99a6.244 6.244 0 016.248-6.24c2 0 3.781.94 4.925 2.403l.75-.586c.083-.064.203-.005.201.099l-.012 2.609c0 .081-.077.14-.155.12l-2.536-.617a.125.125 0 01-.046-.22l.814-.636A4.978 4.978 0 008.187 3a4.979 4.979 0 00-3.534 1.464A4.979 4.979 0 003.187 8a4.98 4.98 0 001.464 3.534 4.977 4.977 0 003.534 1.464 4.979 4.979 0 003.866-1.83.124.124 0 01.173-.018l.79.616a.127.127 0 01.02.178A6.249 6.249 0 011.937 7.99z"
      fill="currentColor"
    />
  </svg>
);
