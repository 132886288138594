/* eslint max-len: 0 */
import React, { SVGProps } from 'react';

export default (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <path
      d="M11.5 7.345c-.672 0-1.303.26-1.779.736a2.506 2.506 0 00-.737 1.78c0 .67.263 1.302.737 1.778a2.507 2.507 0 001.779.737c.672 0 1.303-.263 1.779-.737a2.497 2.497 0 00.737-1.779c0-.671-.26-1.302-.737-1.779a2.497 2.497 0 00-1.779-.736zm7.695-.852a8.14 8.14 0 00-1.794-2.598 8.33 8.33 0 00-2.655-1.748 8.424 8.424 0 00-3.246-.642 8.464 8.464 0 00-3.246.64c-.995.411-1.886 1-2.654 1.747a8.167 8.167 0 00-1.795 2.6 7.977 7.977 0 00-.66 3.189c0 1.585.38 3.164 1.125 4.687.6 1.224 1.437 2.417 2.493 3.551 1.804 1.936 3.695 3.12 4.232 3.437a.986.986 0 001.006 0c.537-.317 2.428-1.5 4.232-3.437 1.055-1.132 1.893-2.327 2.493-3.55.75-1.521 1.13-3.098 1.13-4.686a7.977 7.977 0 00-.66-3.19zm-7.695 7.32a3.953 3.953 0 110-7.906 3.953 3.953 0 010 7.906z"
      fill="#FF7B02"
      stroke="#F0F0F0"
      strokeWidth="1.5"
    />
  </svg>
);
